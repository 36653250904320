import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import Header from './Header';
import Footer from './Footer';

const Layout = () => (
  <main className="App">
    <ToastContainer
      position="top-right"
      autoClose={8000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Slide}
    />
    <Header />
    <div id="content">
      <Outlet />
    </div>
    <Footer />
  </main>
);

export default Layout;
