import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import CONSTANTS from '../../validation';

const {
  ADMIN_KEY,
} = CONSTANTS;

const MarkButton = ({
  markField,
  data = {},
  unmarkedContent = null,
  markedContent = null,
  onClick = undefined,
  showButtonIfMarked = false,
  variant = 'primary',
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dataLoaded = !!Object.keys(data).length;
  const {
    auth: { user: { authority: userRole } = {} } = {},
  } = useAuth();

  const isMarked = !!data[markField];
  const isAdmin = userRole === ADMIN_KEY;

  const handleClick = async () => {
    setIsSubmitting(true);
    await onClick();
    setIsSubmitting(false);
  };

  if (!dataLoaded) {
    return null;
  }

  if (!isAdmin) {
    return isMarked ? markedContent : null;
  }

  const button = (
    <Button
      disabled={isSubmitting}
      variant={variant}
      onClick={handleClick}
    >
      {unmarkedContent}
    </Button>
  );

  if (!isMarked) {
    return button;
  }

  return showButtonIfMarked ? (
    <>
      <div className="mb-2">
        {markedContent}
      </div>
      {button}
    </>
  ) : markedContent;
};

MarkButton.propTypes = {
  data: PropTypes.shape({}),
  markField: PropTypes.string.isRequired,
  unmarkedContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  markedContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  showButtonIfMarked: PropTypes.bool,
  variant: PropTypes.string,
};

MarkButton.defaultProps = {
  unmarkedContent: null,
  markedContent: null,
  onClick: undefined,
  showButtonIfMarked: false,
  variant: 'primary',
  data: {},
};

export default MarkButton;
