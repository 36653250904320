import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Tabs, Tab, Card,
} from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ImageTab from './ImageTab';
import SMMTable from './SMMTable';
import SimilarsTable from './SimilarsTable';
import SourceData from './SourceData';
import MarkButton from './MarkButton';
import { showError } from '../../utils';

const ArticlePage = () => {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [articleDetails, setArticleDetails] = useState({});
  const [imageDetails, setImageDetails] = useState([]);
  const [tab, setTab] = useState('source-data');
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    document.title = `SCOTUSApp - Article ${id}`;

    const getArticleDetails = async () => {
      try {
        const response = await axiosPrivate.get(`/approved/getArticleDetails/${id}/`, {
          signal: controller.signal,
        });
        if (isMounted) {
          setArticleDetails(response.data);
        }
      } catch (error) {
        showError({ error, overrideMessage: 'Failed to retrieve article details.' });
      }
    };

    getArticleDetails();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getImageDetails = async () => {
      try {
        const response = await axiosPrivate.get(`/approved/getArticleImages/${id}/`, {
          signal: controller.signal,
        });
        if (isMounted) {
          setImageDetails(response.data);
        }
      } catch (error) {
        showError({ error, overrideMessage: 'Failed to retrieve image details.' });
      }
    };

    getImageDetails();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
    setTab('source-data');
  }, [id]);

  const displayValue = (value) => ([null, undefined].includes(value) ? 'N/A' : value);

  const { similarArticles } = articleDetails;

  const filterSimilars = (isAfterPublication) => similarArticles?.filter(({
    afterPublication,
  }) => !!afterPublication === isAfterPublication) || [];

  const similarArticlesBeforePublication = filterSimilars(false);
  const similarArticlesAfterPublication = filterSimilars(true);

  const { article_id: articleId } = articleDetails;

  const onRejectArticle = async () => {
    try {
      const { data: { rejectedId } } = await axiosPrivate.post('/admin/rejectArticle/', {
        articleId,
      });
      toast.success(`Article ${articleId} has been moved to rejected data. Rejected ID: ${rejectedId}`);
      navigate('/');
    } catch (error) {
      showError({
        error,
        statusMessages: {
          422: 'Failed to reject: article is either invalid, or part of the training set.',
        },
      });
    }
  };

  const onMarkIncomplete = async () => {
    try {
      await axiosPrivate.post('/admin/markArticleIncomplete/', {
        articleId,
      });
      const refreshedDetails = structuredClone(articleDetails);
      refreshedDetails.marked_incomplete = true;
      setArticleDetails(refreshedDetails);
    } catch (error) {
      showError({ error, overrideMessage: 'Failed to mark as incomplete.' });
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12} lg={3} id="articleDetails">
          <Card className="bg-light text-dark">
            <Card.Header>
              <Card.Title>Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Article ID</Card.Subtitle>
                  <Card.Text>{articleDetails?.article_id}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Alt ID</Card.Subtitle>
                  <Card.Text>{articleDetails?.alt_id}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Author</Card.Subtitle>
                  <Card.Text>{displayValue(articleDetails?.author)}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Source</Card.Subtitle>
                  <Card.Text>{articleDetails?.source?.domain}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Publication Date</Card.Subtitle>
                  <Card.Text>{articleDetails?.published}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Relevancy Score</Card.Subtitle>
                  <Card.Text>{articleDetails?.relevancy_score}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Sentiment Score</Card.Subtitle>
                  <Card.Text>{displayValue(articleDetails?.sentiment_score)}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>Sentiment Magnitude</Card.Subtitle>
                  <Card.Text>{displayValue(articleDetails?.sentiment_magnitude)}</Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <Card.Subtitle>URL</Card.Subtitle>
                  <Card.Text>
                    {articleDetails?.url && (
                      <a className="text-dark link" href={articleDetails?.url || ''} target="_blank" rel="noopener noreferrer">
                        {articleDetails?.url}
                      </a>
                    )}
                  </Card.Text>
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <MarkButton
                    data={articleDetails}
                    markField="marked_irrelevant"
                    variant="danger"
                    unmarkedContent="Reject Article"
                    onClick={onRejectArticle}
                    markedContent={<span className="irrelevantArticle">Marked as irrelevant to SCOTUS.</span>}
                    showButtonIfMarked
                  />
                </Col>
                <Col xs={12} md={4} lg={12}>
                  <MarkButton
                    data={articleDetails}
                    markField="marked_incomplete"
                    variant="warning"
                    unmarkedContent="Mark as Incomplete"
                    onClick={onMarkIncomplete}
                    markedContent={<span className="incompleteArticle">Marked as having incomplete text.</span>}
                  />
                </Col>
              </Row>

            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={9} id="articleContents">
          <Card className="bg-light text-dark h-100">
            <Card.Header>
              <Card.Title id="article-title">{articleDetails?.title}</Card.Title>
            </Card.Header>
            <Card.Header className="my-1">
              <Card.Subtitle className="">
                {articleDetails?.charnum}
                {' '}
                characters
              </Card.Subtitle>
              <Card.Subtitle className="mt-2" id="keywords">Keywords</Card.Subtitle>
              <Card.Text>
                <small>{articleDetails?.keywords?.join(', ')}</small>
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Card.Text id="article-text">{articleDetails?.text}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Card className="bg-light text-dark" id="tabs">
          <Tabs
            id="tabs"
            activeKey={tab}
            onSelect={(key) => setTab(key)}
          >
            <Tab eventKey="source-data" title="Source Data">
              <SourceData data={articleDetails?.source} />
            </Tab>
            <Tab eventKey="smm" title="Social Media Metrics">
              {Object.keys(articleDetails?.social_media_metrics || []).map((metric) => (
                <Row key={metric} className="mt-3">
                  <Col xs={12}>
                    <SMMTable
                      metric={metric}
                      data={articleDetails?.social_media_metrics[metric] || []}
                    />
                  </Col>
                </Row>
              ))}
            </Tab>
            <Tab eventKey="images" title={`Images (${imageDetails?.length})`}>
              <ImageTab images={imageDetails} />
            </Tab>
            <Tab eventKey="similar-articles" title="Similar Articles">
              <Row className="mt-3">
                <Col sm={12}>
                  <SimilarsTable
                    className="sim-table before-pub"
                    title="Before Publication"
                    data={similarArticlesBeforePublication}
                  />
                </Col>
              </Row>
              <Row className="mt-3 mb-3">
                <Col sm={12}>
                  <SimilarsTable
                    className="sim-table after-pub"
                    title="After Publication"
                    data={similarArticlesAfterPublication}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Card>
      </Row>
    </Container>
  );
};

export default ArticlePage;
